<template>
  <div class="tag-container">
    <el-form :inline="true"  class="search-form" :mode="searchData">
      <el-form-item label="标题：">
        <el-input class="inline-input" v-model="searchData.bannerName"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select  placeholder="请选择" v-model="searchData.bannerStatus">
          <el-option value=" " label="全部"></el-option>
          <el-option v-for="(item,index) in bannerStatus" :key="index" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearchArticle">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="operator-bar">
      <el-button type="primary" icon="el-icon-edit" @click="handleCreate">新建banner</el-button>
    </div>

    <el-table :data="listData" max-height="570" :loading="loading == 'list'">
      <el-table-column type="index" :index="indexMethod"  label="序号" width="50" prop="no">
      </el-table-column>

      <el-table-column type="index" label="排序框" width="100" prop="sort">
        <template  slot-scope="scope">
          <el-input v-model.trim="scope.row.sort" type="number"  @change="handleChangeSort(scope.row,scope.row.sort,scope.$index)" @focus="setOriginalData(scope.row.sort)" onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="bannerName" label="banner名称" min-width="200" show-overflow-tooltip  class-name="td-align-left">
      </el-table-column>

      <el-table-column prop="thumb" label="封面图" min-width="100">
        <template  slot-scope="{ row }">
          <img :src="row.thumb" height="50">
        </template>
      </el-table-column>

      <el-table-column prop="bannerStatus" label="状态" width="80">
        <template slot-scope="{ row }">
          <el-tag :type="row.bannerStatus | statusFilter">
            {{ row.bannerStatus | formatStata }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="createTime" label="创建时间" width="150">
        <template slot-scope="{ row }" v-if="row.createTime">
          {{ row.createTime | formatTime('{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>
      
      <el-table-column label="操作" width="150" prop="status">
        <template slot-scope="{row}">
          <el-button type="primary" size="mini" @click="handleUploadStatus(row)">
            {{row.bannerStatus | formatBtn}}
          </el-button>
          <el-button type="primary" @click="goDetail(row.bannerId)" size="mini">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="listPage.total>0" :total="listPage.total" :page.sync="listPage.page" :limit.sync="listPage.pageSize" @pagination="getList" />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getBannerListCount, getBannerList,updateBannerStatus, sortBanner} from '@/api/banner'
import { parseTime } from '@/utils/tool.js'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      columnId: this.$route.meta.columnId,
      loading: false,
      bannerStatus:[
        {
          value: 'HIDDEN',
          name: '隐藏'
        },
        {
          value: 'OPEN',
          name: '开启'
        }
      ],
      searchData: {},
      listData:[],
      listPage: {
        total: 0,
        page: 1,
        pageSize : 10,
      },
      originalData:'',
      sortCalss:''
    }
  },
  created() {
    this.getListCount()
    this.getList()
  },
  methods: {
    indexMethod(index) {
      return this.listPage.pageSize*(this.listPage.page-1)+index+1
    },
    getListCount() {
      const params = {
        columnId: this.$route.meta.columnId,
        bannerName: this.searchData.bannerName,
        bannerStatus: this.searchData.bannerStatus
      }
      getBannerListCount(params).then(res => {
        this.listPage.total = res.data.result
      })
    },
    getList() {
      const params = {
        columnId: this.$route.meta.columnId,
        bannerName: this.searchData.bannerName,
        bannerStatus: this.searchData.bannerStatus,
        page: this.listPage.page,
        pageSize: this.listPage.pageSize
      }
      this.loading = 'list'
      getBannerList(params).then(res => {
        this.listData = res.data.result
        this.loading = false
      })
    },
    //搜索
    handleSearchArticle() {
      this.listPage.page = 1
      this.getListCount()
      this.getList()
    },
    // 修改状态
    handleUploadStatus(row) {
      const status = row.bannerStatus == 'OPEN' ? 'HIDDEN' : 'OPEN'
      const params = {
        bannerId: row.bannerId,
        bannerStatus: status
      }
      updateBannerStatus(params).then(res => {
        row.bannerStatus = status
        this.$message({
          message: '操作成功',
          type: 'success'
        })
      }).catch(error => {
        this.$message({
          message: error,
          type: 'error'
        })
      })
    },
    // 排序
    setOriginalData(val) {
      this.originalData = val
    },
    handleChangeSort(row,val,key) {
      if(val == ''){
        this.$set(row,'sort',this.originalData );
      }else{
        const params = {
          bannerSorts : [
            {
              bannerId: row.bannerId,
              sort: val
            }
          ]
        }
        sortBanner(params)
      }
    },
    goDetail(bannerId) {
      this.$router.push(this.$route.path+'/detail?bannerId='+bannerId)
    },
    handleCreate() {
      this.$router.push(this.$route.path+'/edit')
    }
  },
  filters: {
    // el-tag类型转换
    statusFilter(status) {
      const statusMap = {
        'OPEN': 'success',
        'HIDDEN': 'info'
      }
      return statusMap[status]
    },
    // 状态显示转换
    formatStata(status) {
      const statusMap = {
        'OPEN': '已开启',
        'HIDDEN': '已隐藏'
      }
      return statusMap[status]
    },
    // 按钮显示转换
    formatBtn(status) {
      const statusMap = {
        'OPEN': '隐藏',
        'HIDDEN': '开启'
      }
      return statusMap[status]
    },
    formatTime(time, format) {
      return parseTime(time, format)
    }
  }
}
</script>

<style lang="scss">
.sort-input{
  text-align: center;
}
</style>