import request from '@/utils/request'

// 列表数量
export function getBannerListCount(params) {
  return request({
    url: '/api/banner/list/count',
    method: 'get',
    params
  })
}

// 列表
export function getBannerList(params) {
  return request({
    url: '/api/banner/list',
    method: 'get',
    params
  })
}


// 详情
export function getBannerDetail(params) {
  return request({
    url: '/api/banner/detail',
    method: 'get',
    params
  })
}

// 新建
export function createBanner(data) {
  return request({
    url: '/api/banner/create',
    method: 'post',
    data
  })
}

// 修改
export function updateBanner(data) {
  return request({
    url: '/api/banner/update',
    method: 'post',
    data
  })
}

// 发布/撤销
export function updateBannerStatus(data) {
  return request({
    url: '/api/banner/updateStatus',
    method: 'post',
    data
  })
}

// 排序
export function sortBanner(data) {
  return request({
    url: '/api/banner/sort',
    method: 'post',
    data
  })
}

